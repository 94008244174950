import React from "react";
import classNames from "classnames";
import { Checkbox } from "antd";
import { RememberMeCheckboxProps } from "./RememberMeCheckbox.types";

import styles from "./RememberMeCheckbox.module.scss";

export const RememberMeCheckbox: React.FC<RememberMeCheckboxProps> = ({ value, setter, label, className }) => {
  return (
    <Checkbox
      checked={value}
      onChange={(e) => setter(e.target.checked)}
      data-testid="RememberMeCheckbox"
      className={classNames(styles.root, className)}
    >
      {label}
    </Checkbox>
  );
};

import React, { MouseEvent, useEffect, useState } from "react";
import { isSessionConfirmDataApiError, SessionConfirmDataApiSuccessResponse } from "auth-ui-client-api";
import {
  Button,
  clientLogger,
  defaultMessages,
  errorMessage,
  handleCancelClick,
  handleConfirmClick,
  Message,
  sessionCancelApiRequest,
  sessionConfirmApiRequest,
  sessionConfirmDataApiRequest,
  useProcessContext,
} from "auth-ui-client";
import { ConfirmationLogonProps } from "./ConfirmationLogon.types";
import { ConfirmationPanel } from "../ConfirmationPanel";
import { Feedback } from "@/components/Feedback";
import { Card } from "@/components/Card";

export const ConfirmationLogon: React.FC<ConfirmationLogonProps> = ({
  sessionConfirmToken,
  service,
  onError,
  setShowLoginForm,
}) => {
  const [sessionConfirmData, setSessionConfirmData] = useState<SessionConfirmDataApiSuccessResponse>();
  const [updateSessionConfirmDataTrigger, setUpdateSessionConfirmDataTrigger] = useState<number>(0);

  const [feedback, setFeedback] = useState<Message[]>([]);
  const setSubmitting = useProcessContext()[1];

  const updateSessionConfirmData = () => setUpdateSessionConfirmDataTrigger((prev) => ++prev);

  useEffect(() => {
    sessionConfirmDataApiRequest(sessionConfirmToken)
      .then((res) => {
        if (res && !isSessionConfirmDataApiError(res)) {
          setSessionConfirmData(res);
        } else {
          setFeedback([errorMessage(defaultMessages.SESSION_CONFIRM_DATA_ERROR_MESSAGE)]);
        }
      })
      .catch((err) => {
        setFeedback([errorMessage(defaultMessages.SESSION_CONFIRM_DATA_ERROR_MESSAGE)]);
        clientLogger.error(defaultMessages.SESSION_CONFIRM_DATA_ERROR_MESSAGE, err);
      });
  }, [sessionConfirmToken, updateSessionConfirmDataTrigger]);

  const onConfirm = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await handleConfirmClick({
      sessionConfirmToken,
      setSubmitting,
      onError,
      service,
      sessionConfirmApiRequest,
      setShowLoginForm,
    });
  };

  const onCancel = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    await handleCancelClick({
      sessionConfirmToken,
      setSubmitting,
      onError,
      service,
      sessionCancelApiRequest: sessionCancelApiRequest,
      setShowLoginForm,
    });
  };

  return (
    <>
      <Feedback feedbackMessages={feedback} setFeedbackMessages={setFeedback} />

      <Card>
        <ConfirmationPanel
          sessionConfirmData={sessionConfirmData}
          sessionConfirmToken={sessionConfirmToken}
          updateSessionConfirmData={updateSessionConfirmData}
        />

        <div className="flex flex-wrap items-center gap-4">
          <Button
            onClick={onConfirm}
            size="large"
            type="primary"
            disabled={sessionConfirmData?.maxActiveSessionsReached}
            data-testid="ContinueButton"
            className="w-full tablet:w-auto"
          >
            Продолжить
          </Button>

          <Button
            className="w-full tablet:w-auto"
            onClick={onCancel}
            size="large"
            data-testid="CancelButton"
            type="link"
          >
            Отменить
          </Button>
        </div>
      </Card>
    </>
  );
};

import React from "react";
import { defaultMessages, errorMessage } from "auth-ui-client";
import { isGetAuthCodeUrlApiError } from "auth-ui-client-api";
import { esiaAuthUrlApiRequest } from "auth-ui-client/src/requests/oauth/esiaAuthUrlApiRequest";
import { EsiaPanelProps } from "./EsiaPanel.types";
import { apiError } from "./EsiaPanel.utils";
import styles from "./EsiaPanel.module.scss";

export const EsiaPanel: React.FC<EsiaPanelProps> = ({
  buttonTitle,
  url,
  esiaParams,
  setFeedback,
  messages = defaultMessages,
}) => {
  const getUrlHandler = async () => {
    const result = await esiaAuthUrlApiRequest(url, esiaParams);

    if (isGetAuthCodeUrlApiError(result)) {
      setFeedback([errorMessage(apiError(result, messages))]);

      return;
    }

    if (esiaParams.popupAuth) {
      const width = 800;
      const height = 600;
      const top = (screen.height - height) / 2;
      const left = (screen.width - width) / 2;
      window.open(
        result.authCodeUrl,
        "Esia auth popup",
        `width=${width},height=${height},top=${top},left=${left},location=1,status=0,menubar=0,resizable=0`
      );
    } else {
      window.location.href = result.authCodeUrl;
    }
  };

  return (
    <div className={styles.root}>
      <button type="button" className={styles.btn} onClick={getUrlHandler} data-testid="EsiaButton">
        {buttonTitle}

        <div className={styles.title}>ЕСИА</div>
      </button>
    </div>
  );
};

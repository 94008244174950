import { ErrorMessages, FeedbackMessageContent } from "auth-ui-client";
import { GetAuthCodeUrlApiError } from "auth-ui-client-api";

export const apiError = (apiError: GetAuthCodeUrlApiError, messages: ErrorMessages): FeedbackMessageContent => {
  switch (apiError.errorCode) {
    case "INTERNAL_ERROR":
    case "VALIDATION_ERROR":
      return messages.GENERAL_ERROR_MESSAGE;
  }
};

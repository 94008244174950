import React, { useEffect, useState } from "react";
import { Layout } from "@/components/Layout";
import {
  CaptchaProvider,
  defaultMessages,
  Message,
  ProcessProvider,
  SESSIONCONFIRM_KEY,
  useSessionConfirmToken,
} from "auth-ui-client";
import { LoginServerProps } from "auth-ui-server";
import { LoginForm } from "./LoginForm";
import { ConfirmationLogon } from "./ConfirmationLogon";
import { getInitialMessages } from "./LoginScreen.utils";
import { Feedback } from "@/components/Feedback";

export const LoginScreen: React.FC<LoginServerProps> = ({ pageConfig, pageProps, resultCode, service }) => {
  const applicationTitle = pageConfig.applicationTitle;
  const resultCodeSet = new Set(resultCode);
  const showLoginFormByDefault = !resultCodeSet.has("SERVICE_NOT_FOUND");
  const initialMessages = getInitialMessages(resultCodeSet, service);
  const [errors, setErrors] = useState<Message[]>([...initialMessages]);

  const [sessionConfirmToken, setSessionConfirmToken] = useState<string | null>(null);

  const [showLoginForm, setShowLoginForm] = useState<boolean>(showLoginFormByDefault);

  useSessionConfirmToken(
    global.window,
    pageProps?.sessionConfirm,
    setSessionConfirmToken,
    defaultMessages,
    service,
    setShowLoginForm,
    setErrors
  );

  const onError = (errors: Message[]) => {
    setSessionConfirmToken(null);
    window.localStorage.removeItem(SESSIONCONFIRM_KEY);
    setErrors(errors);
  };

  useEffect(() => {
    if (
      pageProps?.esia?.redirectUrl &&
      errors.findIndex((el) => el.text === defaultMessages.ACCOUNT_DISABLED_MESSAGE) !== -1
    ) {
      history.pushState({}, "", pageProps?.esia?.redirectUrl);
    }
  }, [errors, pageProps?.esia?.redirectUrl]);

  return (
    <ProcessProvider>
      <Layout applicationTitle={applicationTitle} commonProps={pageConfig.commonConfig}>
        {(!pageProps || !service || (!sessionConfirmToken && !showLoginForm)) && (
          <Feedback feedbackMessages={errors} setFeedbackMessages={setErrors} closable={false} />
        )}

        {pageProps && service && !sessionConfirmToken && showLoginForm && (
          <CaptchaProvider captcha={pageProps.captcha}>
            <LoginForm
              pageProps={pageProps}
              pageConfig={pageConfig}
              errors={errors}
              setErrors={setErrors}
              service={service}
              setSessionConfirmToken={setSessionConfirmToken}
              setShowLoginForm={setShowLoginForm}
            />
          </CaptchaProvider>
        )}

        {pageProps && service && sessionConfirmToken && (
          <ConfirmationLogon
            sessionConfirmToken={sessionConfirmToken}
            onError={onError}
            service={service}
            setShowLoginForm={setShowLoginForm}
          />
        )}
      </Layout>
    </ProcessProvider>
  );
};

import { ActiveSessionsPanel } from "../ActiveSessionsPanel";
import React from "react";

import { ConfirmationPanelProps } from "./ConfirmationPanel.types";
import { LastLogonPanel } from "../LastLogonPanel";

export const ConfirmationPanel: React.FC<ConfirmationPanelProps> = ({
  sessionConfirmData,
  sessionConfirmToken,
  updateSessionConfirmData,
}) => {
  if (!sessionConfirmData) return <></>;

  return (
    <>
      {sessionConfirmData.lastLogon && <LastLogonPanel lastLogonData={sessionConfirmData.lastLogon} />}

      {sessionConfirmData.activeSessions && !sessionConfirmData.lastLogon?.accountNeverLoggedIn && (
        <ActiveSessionsPanel
          activeSessionsData={sessionConfirmData.activeSessions}
          sessionConfirmToken={sessionConfirmToken}
          maxActiveSessionsReached={sessionConfirmData.maxActiveSessionsReached}
          updateSessionConfirmData={updateSessionConfirmData}
        />
      )}
    </>
  );
};

import React from "react";

import { errorMessage } from "auth-ui-client";

export const maxActiveSessionsReachedMessage = errorMessage(() => {
  return (
    <>
      <div>
        <p>Превышено количество активных сеансов доступа.</p>
        <p>Для продолжения завершите один из активных сеансов доступа</p>
      </div>
    </>
  );
});

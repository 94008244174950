import React, { useState } from "react";

import { defaultMessages, errorMessage, Message, sessionsWordForm } from "auth-ui-client";
import { ActiveSessionsPanelProps } from "./ActiveSessionsPanel.types";
import { maxActiveSessionsReachedMessage } from "./ActiveSessionsPanel.const";
import { SessionListItem } from "../SessionListItem";
import { Feedback } from "@/components/Feedback";
import { TerminateBtn } from "../TerminateBtn";

export const ActiveSessionsPanel: React.FC<ActiveSessionsPanelProps> = ({
  activeSessionsData,
  sessionConfirmToken,
  maxActiveSessionsReached,
  updateSessionConfirmData,
  messages = defaultMessages,
}) => {
  const [feedback, setFeedback] = useState<Message[]>(
    maxActiveSessionsReached ? [maxActiveSessionsReachedMessage] : []
  );

  const hasActiveSessions = activeSessionsData.length > 0;

  const handleSuccess = () => {
    setFeedback([]);
    updateSessionConfirmData();
  };

  const handleError = (message: string) => {
    setFeedback([errorMessage(message)]);
  };

  return (
    <div>
      {hasActiveSessions ? (
        <>
          <Feedback feedbackMessages={feedback} setFeedbackMessages={setFeedback} />

          <div className="mb-4">
            <h3 className="mb-4">
              {activeSessionsData.length} {sessionsWordForm(activeSessionsData.length)} доступа
            </h3>

            <TerminateBtn
              sessionConfirmToken={sessionConfirmToken}
              onSuccess={handleSuccess}
              onError={handleError.bind(this, messages.SESSION_TERMINATE_ALL_ERROR_MESSAGE)}
            />
          </div>

          {activeSessionsData.map((session) => (
            <SessionListItem key={session.id} item={session}>
              <TerminateBtn
                sessionConfirmToken={sessionConfirmToken}
                sessionId={session.id}
                onSuccess={handleSuccess}
                onError={handleError.bind(this, messages.SESSION_TERMINATE_ERROR_MESSAGE)}
              />
            </SessionListItem>
          ))}
        </>
      ) : (
        <h3 className="mb-4">Нет активных сеансов доступа</h3>
      )}
    </div>
  );
};

import React from "react";

import { logonStatusInfo } from "auth-ui-client";
import { SessionListItemProps } from "./SessionListItem.types";
import { FieldView, getDateTime } from "@/components/FieldView";
import { kigFieldNames } from "@/localizations/KigFieldNames";

export const SessionListItem: React.FC<SessionListItemProps> = ({ item, children }) => {
  const lastLogoinStatus = logonStatusInfo(item.status);

  const local: Record<string, string> = {
    login: kigFieldNames.LOGIN_LABEL,
    email: kigFieldNames.EMAIL_LABEL,
    mobile: kigFieldNames.MOBILE_LABEL,
    name: kigFieldNames.ACCOUNT_LABEL,
  };

  const additional = Object.keys(item.additional.identifierType).map((key) => {
    const value = (item.additional.identifierType as Record<string, string>)[key];

    return local[key] ? (
      <FieldView key={key} label={local[key]} value={value} />
    ) : (
      <div key={key} className="mb-3">
        {value}
      </div>
    );
  });

  return (
    <div className="mb-4 border-gray-300 border-b">
      <FieldView label="Время начала" value={getDateTime(item.startedAt)} />

      <FieldView label="Приложения" value={item.services.length ? item.services : "Вход в приложения не выполнялся"} />

      <FieldView label="IP адрес" value={item.client.ipAddress} />

      <FieldView label="Браузер" value={item.client.browser} />

      <FieldView label="Операционна система" value={item.client.os} />

      {lastLogoinStatus.closed ? (
        <FieldView label="Сеанс завершен" value={getDateTime(lastLogoinStatus.date)} />
      ) : (
        <FieldView
          label="Сеанс активен"
          value={`${lastLogoinStatus.date && `до `}${getDateTime(lastLogoinStatus.date)}`}
        />
      )}

      {additional}

      <div>{children}</div>
    </div>
  );
};

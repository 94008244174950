import React, { FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import { useFirefoxAutofillEffect } from "auth-ui-client";
import { PasswordInputProps } from "./PasswordInput.types";
import Image from "next/image";
import eyeOn from "./eyeOn.svg";
import eyeOff from "./eyeOff.svg";
import lock from "./lock.svg";
import { Input } from "../Input";

export const PasswordInput: FC<PasswordInputProps> = ({
  id,
  name,
  value,
  setter,
  canViewPassword,
  viewPasswordLimitSec,
  placeholder = "",
  testId,
  autoComplete,
  browser,
  className,
}) => {
  const [type, setType] = useState("password");
  const passwordVisibilityTimerId = useRef<number>();

  useFirefoxAutofillEffect(setType, browser);

  useEffect(() => {
    return () => window.clearTimeout(passwordVisibilityTimerId.current);
  }, []);

  const clickEyeHandler = () => {
    const passwordHidden = type === "password";
    if (passwordHidden) {
      setType("text");
      passwordVisibilityTimerId.current = window.setTimeout(() => {
        setType("password");
      }, viewPasswordLimitSec * 1000);
    } else {
      setType("password");
      window.clearTimeout(passwordVisibilityTimerId.current);
    }
  };

  const keyPressEyeHandler = (e: KeyboardEvent) => {
    if (e.key === "Enter") {
      clickEyeHandler();
    }
  };

  const passwordVisible = type === "text";

  const suffix = (
    <Image
      tabIndex={0}
      src={passwordVisible ? eyeOff : eyeOn}
      title={passwordVisible ? "Скрыть пароль" : "Показать пароль"}
      onClick={clickEyeHandler}
      onKeyPress={keyPressEyeHandler}
      className="cursor-pointer"
      alt=""
    />
  );

  const prefix = <Image src={lock} alt="" />;

  return (
    <Input
      id={id}
      title={placeholder}
      role="textbox"
      name={name}
      className={className}
      type={type}
      placeholder={placeholder}
      value={value}
      setter={setter}
      maxLength={256}
      onChange={(event) => {
        event.preventDefault();
        setter(event.target.value);
      }}
      testId={testId}
      autoComplete={autoComplete}
      prefix={prefix}
      suffix={canViewPassword ? suffix : undefined}
    />
  );
};

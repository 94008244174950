import React from "react";
import { AccountLoggedIn } from "auth-ui-client-api";
import { LastLogonPanelProps } from "./LastLogonPanel.types";

import { SessionListItem } from "../SessionListItem";

export const LastLogonPanel: React.FC<LastLogonPanelProps> = ({ lastLogonData }) => {
  const hasLogonData = (logonData: LastLogonPanelProps["lastLogonData"]): logonData is AccountLoggedIn =>
    !logonData.accountNeverLoggedIn;

  return (
    <div className="mb-4">
      {hasLogonData(lastLogonData) ? (
        <>
          <h3 className="mb-4">Последний вход в систему</h3>

          <SessionListItem item={lastLogonData.data} />
        </>
      ) : (
        <h3>Вход в систему не выполнялся</h3>
      )}
    </div>
  );
};

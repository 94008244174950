import React, { ReactElement } from "react";

import { getLoginServerSideProps, LoginServerProps } from "auth-ui-server";
import { LoginScreen } from "@/screens/LoginScreen";

export default function Login(props: LoginServerProps): ReactElement {
  return <LoginScreen {...props} />;
}

// noinspection JSUnusedGlobalSymbols
export const getServerSideProps = getLoginServerSideProps;

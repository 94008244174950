import React from "react";
import { FieldViewProps } from "./FieldView.types";

export const FieldView: React.FC<FieldViewProps> = ({ value, label, separator = ", ", placeholder = "-" }) => {
  const val = Array.isArray(value) ? value.filter(Boolean).join(separator) : value;
  const valView = val == null || val === "" ? placeholder : val;

  return (
    <div className="mb-3">
      <div className="text-gray-400">{label}:</div>
      <div>{valView}</div>
    </div>
  );
};

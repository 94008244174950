import { Popconfirm } from "antd";
import React, { useState } from "react";

import { Button, sessionConfirmTerminateAllApiRequest, sessionConfirmTerminateApiRequest } from "auth-ui-client";
import { TerminateBtnProps } from "./TerminateBtn.types";

export const TerminateBtn: React.FC<TerminateBtnProps> = ({ sessionConfirmToken, sessionId, onSuccess, onError }) => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleSessionTerminateClick = async () => {
    if (sessionId) {
      await sessionConfirmTerminateApiRequest(sessionConfirmToken, sessionId, onSuccess, onError);
    } else {
      await sessionConfirmTerminateAllApiRequest(sessionConfirmToken, onSuccess, onError);
    }
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showPopupConfirm = () => {
    setVisible(true);
  };

  return (
    <Popconfirm
      title={sessionId ? "Подтвердите завершение сеанса" : "Подтвердите завершение сеансов"}
      visible={visible}
      onConfirm={handleSessionTerminateClick}
      onCancel={handleCancel}
      okText="Завершить"
      cancelText="Отменить"
      icon=""
      className="PopupConfirm"
    >
      <Button
        title={sessionId ? "Завершение сеанса" : "Завершение сеансов"}
        onClick={showPopupConfirm}
        data-testid={sessionId ? "TerminateButton" : "TerminateAllButton"}
        className="mb-3"
      >
        {sessionId ? "Завершить" : "Завершить все"}
      </Button>
    </Popconfirm>
  );
};

import React from "react";
import { changePasswordLink, recoveryPasswordLink } from "auth-ui-client";

import { kigFieldNames } from "@/localizations/KigFieldNames";
import { LoginFormLinksProps } from "./LoginFormLinks.types";
import classNames from "classnames";
import { ButtonLink } from "@/components/ButtonLink";

const { RECOVERY_LINK, REGISTRATION_LINK, CHANGE_PASSWORD_LINK } = kigFieldNames;

export const LoginFormLinks: React.FC<LoginFormLinksProps> = ({ service, pageConfig, storageId, className }) => {
  const { passwordRecovery, showRegistrationLink, urls } = pageConfig;

  return (
    <div className={classNames("flex flex-wrap justify-between", className)}>
      <ButtonLink url={changePasswordLink({ service })} testId="ChangePasswordLink">
        {CHANGE_PASSWORD_LINK}
      </ButtonLink>

      {passwordRecovery && (
        <ButtonLink url={recoveryPasswordLink({ service, storageId })} testId="RecoveryLink">
          {RECOVERY_LINK}
        </ButtonLink>
      )}

      {showRegistrationLink && urls.registrationUrl && (
        <ButtonLink url={urls.registrationUrl} testId="RegistrationLink">
          {REGISTRATION_LINK}
        </ButtonLink>
      )}
    </div>
  );
};

import React, { useState } from "react";

import { Assignment, Identifier, IdentifierType, IdentifierTypes, StorageItem } from "auth-ui-client-api";
import {
  checkLoginFormEmptiness,
  defaultMessages,
  loginApiRequest,
  LoginFormData,
  onLoginSubmit,
  requiredFieldsErrorMessage,
  showRememberMeForIdentifier,
  supportedIdentifierTypes,
  useAccount,
  useAssignment,
  useAssignments,
  useCaptchaContext,
  useIdentifierTypeTab,
  useNavigatorData,
  useProcessContext,
  useStorage,
  ValidationInfo,
} from "auth-ui-client";

import { RememberMeCheckbox } from "../RememberMeCheckbox";
import { kigFieldNames } from "@/localizations/KigFieldNames";
import { CaptchaPanel } from "@/components/CaptchaPanel";
import { Form } from "antd";
import { StorageSelectField } from "@/components/StorageSelectField";

import { EsiaPanel } from "../EsiaPanel";
import { LoginFormProps } from "./LoginForm.types";
import { Feedback } from "@/components/Feedback";
import { Tabs } from "@/components/Tabs";
import { LoginFormLinks } from "../LoginFormLinks";
import { Button } from "@/components/Button";
import { PasswordInput } from "@/components/PasswordInput";
import { IdentifierInput } from "@/components/IdentifierInput";
import { FormItem } from "@/components/FormItem";

const { PASSWORD_LABEL, STORAGE_LABEL, REMEMBER_ME, LOGIN_BTN_SIGN, ESIA_BTN_SIGN } = kigFieldNames;

export const LoginForm: React.FC<LoginFormProps> = ({
  service,
  errors,
  setErrors,
  setSessionConfirmToken,
  setShowLoginForm,
  pageConfig,
  pageProps,
}) => {
  const { passwordView, passwordViewLimitSec, urls } = pageConfig;
  const {
    rememberMeOption,
    rememberSelectedValues,
    values: { fromService, fromQuery },
    esia,
  } = pageProps;

  const [storage, setStorage] = useState<StorageItem | null>(fromQuery.storage);
  const [identifierType, setIdentifierType] = useState<IdentifierType>(IdentifierTypes.LOGIN);
  const [identifier, setIdentifier] = useState<Identifier>({
    login: "",
    email: "",
    mobile: "",
    account: null,
    assignment: null,
    type: identifierType,
  });

  const [mobileValidation, setMobileValidation] = useState<ValidationInfo>();
  const [emailValidation, setEmailValidation] = useState<ValidationInfo>();
  const [form] = Form.useForm();

  const [password, setPassword] = useState("");
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [rememberMe, setRememberMe] = useState(false);
  const [os, setOs] = useState<string>();
  const [browser, setBrowser] = useState<string>();

  const [captcha, dispatchCaptcha] = useCaptchaContext();
  const setSubmitting = useProcessContext()[1];

  const showRememberMe = showRememberMeForIdentifier(identifier, rememberMeOption);
  const showTabs = fromService.allIdentifierTypes.length !== 1;
  const showStorage = fromService.allStorages.length !== 1;
  const allIdentifierTypes = supportedIdentifierTypes(fromService.allIdentifierTypes);

  const setTab = (tab: IdentifierType | null) => {
    setIdentifierType(tab || "LOGIN");
    setIdentifier((prevState) => {
      return {
        ...prevState,
        type: tab || "LOGIN",
      };
    });
  };

  useNavigatorData(setOs, setBrowser);

  useIdentifierTypeTab(fromQuery.identifierType, fromService.identifierType, allIdentifierTypes, setTab);
  useStorage(fromQuery.storage, fromService.storage, rememberSelectedValues, fromService.allStorages, setStorage);
  useAccount(fromQuery.identifier, rememberSelectedValues, identifierType, setIdentifier);
  useAssignments(identifier, setAssignments);
  useAssignment(fromQuery.assignmentId, rememberSelectedValues, assignments, setIdentifier);

  const onStorageChange = async (value: StorageItem | null) => {
    setStorage(value);
    setIdentifier((prevState) => {
      return {
        ...prevState,
        account: null,
        assignment: null,
      };
    });
  };

  const emptyFields = checkLoginFormEmptiness(identifier, captcha, password, storage, assignments.length > 0);

  const isDisabledSubmitButton = !!emptyFields && emptyFields.length > 0;

  const submitButtonTitle = isDisabledSubmitButton
    ? requiredFieldsErrorMessage(
        emptyFields.map(kigFieldNames.fieldName),
        defaultMessages.FIELDS_REQUIRED_PREFIX,
        defaultMessages.FIELD_REQUIRED_PREFIX
      )
    : undefined;

  const captchaClearField = () => {
    form.resetFields(["captcha"]);
  };

  const customValidationOptions = { setMobileValidation, setEmailValidation, captchaClearField };

  const resetValidation = (e: React.FormEvent<HTMLFormElement>) => {
    const target = e.target as HTMLInputElement;

    switch (target.name) {
      case "EMAIL":
        setEmailValidation(undefined);
    }
  };

  const onSubmit = async () => {
    const formData = new LoginFormData(captcha, rememberMe, storage, identifier, password, os, browser);

    await onLoginSubmit({
      validation: pageConfig.validation,
      isAssignmentsEmpty: assignments.length > 0,
      formData,
      rememberSelectedValues,
      service,
      dispatchCaptcha,
      setSubmitting,
      setSessionConfirmToken,
      loginApiRequest,
      urls,
      setFeedback: setErrors,
      setShowLoginForm,
      fieldNames: kigFieldNames,
      customValidationOptions,
    });
  };

  return (
    <>
      <Feedback feedbackMessages={errors} setFeedbackMessages={setErrors} />

      <Form form={form} className="mt-6" onChange={resetValidation} layout="vertical" onFinish={onSubmit}>
        {/* TODO: <- нет макетов */}
        {showTabs && (
          <Tabs
            filteredTypes={allIdentifierTypes}
            currentType={identifierType}
            onTabClick={setTab}
            fieldNames={kigFieldNames}
          />
        )}

        {showStorage && (
          <FormItem htmlFor="storage" label={STORAGE_LABEL}>
            <StorageSelectField
              id="storage"
              value={storage}
              setter={onStorageChange}
              storages={pageProps.values.fromService.allStorages}
              placeholder={kigFieldNames.STORAGE_PLACEHOLDER}
            />
          </FormItem>
        )}
        {/* -> */}

        <IdentifierInput
          identifierType={identifierType}
          storage={storage}
          assignments={assignments}
          identifier={identifier}
          setIdentifier={setIdentifier}
          fieldNames={kigFieldNames}
          showLabel={true}
          showPlaceholder={true}
          emailValidation={emailValidation}
          mobileValidation={mobileValidation}
          setMobileValidation={setMobileValidation}
        />

        <FormItem htmlFor="password" label={PASSWORD_LABEL}>
          <PasswordInput
            id="password"
            name="password"
            value={password}
            setter={setPassword}
            canViewPassword={passwordView}
            viewPasswordLimitSec={passwordViewLimitSec}
            testId="PasswordInput"
            placeholder={kigFieldNames.PASSWORD_PLACEHOLDER}
            browser={browser}
          />
        </FormItem>

        {showRememberMe && (
          <RememberMeCheckbox value={rememberMe} setter={setRememberMe} label={REMEMBER_ME} className="mt-3 mb-6" />
        )}

        <CaptchaPanel
          setErrors={setErrors}
          captchaImageError={defaultMessages.CAPTCHA_IMAGE_ERROR}
          placeholder={kigFieldNames.CAPTCHA_PLACEHOLDER}
          form={form}
          className="mb-6"
        />

        <Button
          data-testid="LoginButton"
          size="large"
          type="accent"
          disabled={isDisabledSubmitButton}
          title={submitButtonTitle}
          className="mb-6 w-full"
          htmlType="submit"
        >
          {LOGIN_BTN_SIGN}
        </Button>

        <LoginFormLinks service={service} pageConfig={pageConfig} storageId={storage?.id} />

        {/* TODO: <- нет макетов */}
        {esia && (
          <EsiaPanel
            url={esia.url}
            esiaParams={{
              storageId: esia.esiaStorageId,
              redirectUrl: esia.redirectUrl,
              popupAuth: esia.popupAuth,
              os,
              browser,
            }}
            setFeedback={setErrors}
            buttonTitle={ESIA_BTN_SIGN}
          />
        )}
        {/* -> */}
      </Form>
    </>
  );
};

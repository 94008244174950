import React from "react";
import { calcIdentifierTabName, testIdForIdentifier } from "auth-ui-client";
import { TabsProps } from "./Tabs.types";
import styles from "./Tabs.module.scss";
import classNames from "classnames";

export const Tabs: React.FC<TabsProps> = ({ filteredTypes, currentType, onTabClick, fieldNames }) => {
  return (
    <div className={classNames(styles.root, "flex flex-wrap mb-4")} role="tablist">
      {filteredTypes.map((item) => (
        <a
          key={item}
          className={classNames(styles.link, "px-3 tablet:px-6 py-1.5", currentType === item && styles.active, item)}
          onClick={() => onTabClick(item)}
          role="tab"
          data-testid={testIdForIdentifier(item, fieldNames)}
          tabIndex={0}
        >
          {calcIdentifierTabName(item, fieldNames)}
        </a>
      ))}
    </div>
  );
};
